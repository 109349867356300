import { NUXT_APP_BIBLE_API } from "~/env";

let yourbibleApi = NUXT_APP_BIBLE_API;

export function resolveImage(imagePath: string, options?: any) {
    const isEmpty = !imagePath;
    const isString = typeof (imagePath) == 'string';

    if (isEmpty || !isString) return null;

    if (isBase64Image(imagePath)) return imagePath;
    if (isAbsolutePath(imagePath)) return imagePath;

    // if (process.dev == 'dev')
    //     yourbibleApi = 'https://yourbible-api.bibleawr.com';

    const remotePath = [yourbibleApi, imagePath].join('/');

    if(options?.randomize)
        return remotePath + '?' + Math.round(Math.random() * 1000);

    return remotePath;
}

function isBase64Image(source: string) {
    if (!source || typeof (source) != 'string') return false;
    return source?.indexOf("data:image") == 0;
}

function isAbsolutePath(path: string) {
    return path.indexOf('http') == 0;
}

export function resolveSiteIcon(url: string) {
    return `https://s2.googleusercontent.com/s2/favicons?sz=128&domain_url=${url}`;
}
